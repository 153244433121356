import { createMuiTheme } from "@material-ui/core/styles";
import { frFR, enUS } from '@material-ui/core/locale';

import variants from "./variants";
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";

const theme = (variant, lang) => {
  const locale = lang === 'fr' ? frFR : enUS;

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      overrides: overrides,
      props: props,
      typography: typography,
      shadows: shadows,
      body: variant.body,
      header: variant.header,
      palette: variant.palette,
      sidebar: variant.sidebar,
      footer: variant.footer
    },
    variant.name,
    locale
  );
};

const themes = variants.map(variant => lang => theme(variant, lang));

export default themes;
