import React from "react";

import async from "../components/Async";

import { User, Users } from "react-feather";

import {
  AttachFile as LegalIcon,
  // People as PeopleIcon,
  Contacts as ContactsIcon,
  // Help as HelpIcon,
  Dashboard as DashboardIcon,
  EuroSymbol as InvoiceIcon,
  InsertDriveFile as CommercialAgreementsIcon,
} from "@material-ui/icons";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
//const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const PageNetworkError = async(() => import("../pages/auth/PageNetworkError"));

// Pages components
const CreateContractorForm = async(() =>
  import("../pages/components/CreateContractorForm")
);
const Profile = async(() => import("../pages/pages/Profile"));
const Team = async(() => import("../pages/pages/Team"));
const TeamContactDetails = async(() =>
  import("../pages/pages/TeamContactDetails")
);
// const Umbrella = async(() => import("../pages/pages/Umbrella"));
const Contacts = async(() => import("../pages/pages/Contacts"));
// const CommercialAgreementSigningList = async(() =>
//   import("../pages/pages/CommercialAgreementSigningList")
// );
const CommercialAgreementActiveList = async(() =>
  import("../pages/pages/CommercialAgreementActiveList")
);
const CommercialAgreementDetails = async(() =>
  import("../pages/pages/CommercialAgreementDetails")
);
const CommercialAgreementForthcomingList = async(() =>
  import("../pages/pages/CommercialAgreementForthcomingList")
);
const CommercialAgreementDetaisToSignList = async(() =>
  import("../pages/pages/CommercialAgreementToSignList")
);
const LegalDocumentsList = async(() => import("../pages/pages/LegalDocuments"));
const SalesInvoiceWaitingList = async(() =>
  import("../pages/pages/SalesInvoiceWaitingList")
);
const SalesInvoicePaidList = async(() =>
  import("../pages/pages/SalesInvoicePaidList")
);
const SalesInvoiceCanceledList = async(() =>
  import("../pages/pages/SalesInvoiceCanceledList")
);
const SalesInvoiceDetails = async(() =>
  import("../pages/pages/SalesInvoiceDetails")
);
//const Directory = async(() => import("../pages/pages/DirectoryList")); // Modif LUGU: Suppression de la CVThèque dans le menu
const ContractorDetails = async(() =>
  import("../pages/pages/ContractorDetails")
);
const Dashboard = async(() => import("../pages/pages/Dashboard"));
// const ProfileNotFound = async(() => import("../pages/pages/ContactNotFound"));
const ConventionActiveList = async(() =>
  import("../pages/pages/ConventionsActiveList")
);
const ConventionEndedList = async(() =>
  import("../pages/pages/ConventionsEndedList")
);

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/login",
      name: "Connexion",
      component: SignIn,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/500",
      name: "Erreur interne",
      component: Page500,
    },
    {
      path: "/networkerror",
      name: "Erreur réseau",
      component: PageNetworkError,
    },
  ],
};

const profileRoutes = {
  id: "profile",
  path: "/profil",
  icon: <User />,
  component: Profile,
  children: null,
};

const createContractorFormRoute = {
  id: "create-contractor-form",
  type: "button",
  component: <CreateContractorForm />,
};

const dashboardRoutes = {
  id: "dashboard",
  path: "/",
  icon: <DashboardIcon />,
  component: Dashboard,
  children: null,
  isHome: true,
};

// const profileNotFound = {
//   id: "profileNotFound",
//   path: "/profilenotfound",
//   icon: <HelpIcon />,
//   component: ProfileNotFound,
//   children: null,
// };

const teamRoutes = {
  id: "team",
  path: "/team",
  icon: <User />,
  component: Team,
  children: null,
};

const teamContactDetailsRoutes = {
  id: "teamContactDetails",
  path: "/team/:no",
  icon: <User />,
  component: TeamContactDetails,
  children: null,
};

// const umbrellaRoutes = {
//   id: "umbrella",
//   path: "/umbrella",
//   icon: <User />,
//   component: Umbrella,
//   children: null,
// };

const contactsRoutes = {
  id: "2ip-contacts",
  path: "/contacts",
  icon: <ContactsIcon />,
  component: Contacts,
  children: null,
};

const legalRoutes = {
  id: "legal-kit",
  path: "/legal",
  icon: <LegalIcon />,
  component: LegalDocumentsList,
  children: null,
};

const commercialAgreementList = {
  id: "commercialAgreement",
  path: "/commercial-agreements",
  icon: <CommercialAgreementsIcon />,
  children: [
    {
      path: "/commercial-agreements/forthcoming",
      name: "ca-forthcoming",
      component: CommercialAgreementForthcomingList,
    },
    {
      path: "/commercial-agreements/tosign",
      name: "ca-tosign",
      component: CommercialAgreementDetaisToSignList,
    },
    {
      path: "/commercial-agreements/active",
      name: "ca-actives",
      component: CommercialAgreementActiveList,
    },
  ],
};

const conventionsList = {
  id: "conventions",
  path: "/conventions",
  icon: <CommercialAgreementsIcon />,
  children: [
    {
      path: "/conventions/active",
      name: "conventions-active",
      component: ConventionActiveList,
    },
    {
      path: "/conventions/ended",
      name: "conventions-ended",
      component: ConventionEndedList,
    },
  ],
};

const commercialAgreementDetails = {
  id: "CommercialAgreement",
  path: "/commercial-agreements/:no",
  icon: <CommercialAgreementsIcon />,
  component: CommercialAgreementDetails,
  children: null,
};

const salesInvoiceList = {
  id: "Invoices",
  path: "/sales-invoice",
  icon: <InvoiceIcon />,
  children: [
    {
      path: "/sales-invoice/waiting",
      name: "invoices-waiting",
      component: SalesInvoiceWaitingList,
    },
    {
      path: "/sales-invoice/paid",
      name: "invoices-paid",
      component: SalesInvoicePaidList,
    },
    {
      path: "/sales-invoice/cancelled",
      name: "invoices-cancelled",
      component: SalesInvoiceCanceledList,
    },
  ],
};

const salesInvoiceDetails = {
  id: "invoice",
  path: "/sales-invoice/:no",
  icon: <InvoiceIcon />,
  component: SalesInvoiceDetails,
  children: null,
};
/** modif LUGU: Suppression de la CVThèque
const directoryRoutes = {
  id: "directory",
  path: "/directory",
  icon: <PeopleIcon />,
  component: Directory,
  children: null
};
*/
const contractorDetailsRoutes = {
  id: "contractorDetails",
  path: "/contractor/:no",
  icon: <User />,
  component: ContractorDetails,
  children: null,
};

// Mise en commentaire du module des évènements
/*const eventsRoutes = {
  id: "events",
  path: "/events",
  icon: <EventsIcon />,
  component: Events,
  children: null
};*/

export const dashboard = [
  dashboardRoutes,
  // umbrellaRoutes,
  contactsRoutes,
  legalRoutes,
  commercialAgreementList,
  commercialAgreementDetails,
  salesInvoiceList,
  profileRoutes,
  teamRoutes,
  teamContactDetailsRoutes,
  salesInvoiceDetails,
  // profileNotFound,
  //directoryRoutes,
  contractorDetailsRoutes,
  conventionsList,
  //eventsRoutes
];

export const auth = [authRoutes];

export default [
  createContractorFormRoute,
  dashboardRoutes,
  contactsRoutes,
  legalRoutes,
  conventionsList,
  commercialAgreementList,
  salesInvoiceList,
  // profileNotFound,
  // umbrellaRoutes,
  //eventsRoutes
];
